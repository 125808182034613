import React, { useMemo } from 'react';
import MonospaceText from '../MonospaceFont/MonospaceFont';
import './gameStopwatch.css';

const GameStopwatch = ({
    gameElapsedSeconds=0
}) => {
    const [displayMinutes, displaySeconds] = useMemo(() => {
        const minutes = Math.floor(gameElapsedSeconds / 60);
        const seconds = gameElapsedSeconds % 60;
        // add a leading zero to seconds (e.g. 0:1 becomes 0:01)
        return [minutes.toString(), ('0' + seconds).slice(-2)]
    }, [gameElapsedSeconds])

    return (
        <div className='gs-container'>
            <img 
                className='gs-icon'
                src='/images/icon/hourglass.png' 
                alt='Time' 
            />
            <p className='gs-text'>
                <MonospaceText textStr={displayMinutes} />:<MonospaceText textStr={displaySeconds} />
            </p>
        </div>
    );
};

export default GameStopwatch;