import React from 'react';
import BannerActionRow from './BannerActionRow';
import MarkedCellCountdown from '../MarkedCellCountdown/MarkedCellCountdown';
import NewGameButton from '../NewGameButton/NewGameButton';
import DifficultySelector from '../DifficultySelector/DifficultySelector';
import CellActionSelector from '../CellActionSelector/CellActionSelector';
import GameStopwatch from '../GameStopwatch/GameStopwatch';
import './gameActionRow.css';

const GameActionRow = ({
    markedCellCount,
    mineCount,
    onStartNewGame,
    difficulty,
    onDifficultyChange,
    cellLeftClickActionType,
    onCellLeftClickActionTypeChange,
    gameElapsedSeconds
}) => {

    return (
        <BannerActionRow
            actions={[
                {           
                    component: (
                        <NewGameButton 
                            onClick={onStartNewGame} 
                        />
                    )
                },
                {           
                    component: (
                        <DifficultySelector 
                            difficulty={difficulty}
                            onDifficultyChange={onDifficultyChange}
                        />
                    )
                },


                /*

                todos:
                    - use the custom select background images in the diff selector
                    - maybe make the action toggle button a big square next to the column on mobile
                    - finish stying the gar completely before styling the board

                */


                {        
                    component: (
                        <CellActionSelector
                            cellLeftClickActionType={cellLeftClickActionType}
                            onCellLeftClickActionTypeChange={onCellLeftClickActionTypeChange}
                        />
                    )
                },
                {       
                    component: (
                        <div className='gar-counts-container'>
                            <MarkedCellCountdown 
                                markedCellCount={markedCellCount} 
                                mineCount={mineCount} 
                            />
                            <GameStopwatch
                                gameElapsedSeconds={gameElapsedSeconds}
                            />
                        </div>
                    )
                },
            ]}
        />
    )
}

export default GameActionRow;