import React, { useMemo } from 'react';
import Button from '../Button/Button';
import './cellActionSelector.css';

const CellActionSelector = ({
    cellLeftClickActionType, // 'reveal' or 'cycle_cell_mark'
    onCellLeftClickActionTypeChange=(newCellLeftClickActionType)=>null
}) => {
    
    const cycleLeftClickAction = () => {
        const nextActionMap = {
            reveal: 'cycle_cell_mark',
            cycle_cell_mark: 'reveal',
        }
        onCellLeftClickActionTypeChange(nextActionMap[cellLeftClickActionType]);
    }

    // choose which icon to use when the action type changes
    const buttonIcon = useMemo(() => {
        const actionIconMap = {
            reveal: {
                icon: '/images/icon/shovel.png',
                alt: 'Reveal'
            },
            cycle_cell_mark: {
                icon: '/images/icon/flag.png',
                alt: 'Flag'
            },
        }
        const { icon, alt } = actionIconMap[cellLeftClickActionType];
        return (
            <img 
                className='cas-btn-icon'
                src={icon} 
                alt={alt} 
            />
        );
    }, [cellLeftClickActionType])
    
    return (
        <Button 
            shape='square'
            styleType='default'
            tileSize='md'
            onClick={(e) => cycleLeftClickAction()}
        >
            {buttonIcon}
        </Button>
    )
}

export default CellActionSelector;