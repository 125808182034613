import * as React from 'react';
import PropTypes from 'prop-types';
import { Select, selectClasses } from '@mui/base/Select';
import { Option, optionClasses } from '@mui/base/Option';
import { PopupContext } from '@mui/base/Unstable_Popup';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import { CssTransition } from '@mui/base';
import './select.css';

const CustomSelect = ({
    className='',
    value,
    onChange=(newValue)=>null,
    options=[{ value: '', label: '' }],
    tileSize='sm', // (string) 'sm', 'md', 'lg'. How big the select should be
}) => {
    return (
        <>
            <Select
                className={`select select-${tileSize} ${className}`}
                slots={{
                    root: Button,
                    listbox: AnimatedListbox,
                }}
                slotProps={{
                    listbox: { className: 'select-listbox' },
                    popup: { className: 'select-popup' },
                }}
                value={value}
                onChange={(e, newValue) => onChange(newValue)}
            >
                {options.map((option, index) => {
                    return (
                        <Option 
                            key={index} 
                            className='select-option' 
                            value={option.value}
                        >
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
            <DynamicClassStyles />
        </>
    );
}
export default CustomSelect;

// the select trigger and active option
const Button = React.forwardRef(function Button(props, ref) {
    const { ownerState, ...other } = props;
    return (
        <button type='button' {...other} ref={ref}>
            {other.children}
            <UnfoldMoreRoundedIcon />
        </button>
    );
});
Button.propTypes = {
    children: PropTypes.node,
    ownerState: PropTypes.object.isRequired,
};

// the list of options
const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
    const { ownerState, ...other } = props;
    const popupContext = React.useContext(PopupContext);

    if (popupContext == null) {
        throw new Error(
            'The `AnimatedListbox` component cannot be rendered outside a `Popup` component',
        );
    }

    const verticalPlacement = popupContext.placement.split('-')[0];

    return (
        <CssTransition
            className={`placement-${verticalPlacement}`}
            enterClassName='open'
            exitClassName='closed'
        >
            <ul {...other} ref={ref} />
        </CssTransition>
    );
});
AnimatedListbox.propTypes = {
    ownerState: PropTypes.object.isRequired,
};

const DynamicClassStyles = () => {
    return (
        <style>
            {`
            .select {
                &.${selectClasses.expanded} {
                    background-image: url('/images/select/select-red-3-long-pressed.png');
                    padding-top: calc(var(--select-size) * 0.22);
                }

                &.${selectClasses.expanded} > svg {
                    height: 100%;
                }
            }

            .select-option {
                &.${optionClasses.selected} {
                    background-color: grey;
                    color: darkgrey;
                }
            
                &.${optionClasses.highlighted} {
                    background-color: grey;
                    color: darkgrey;
                }
            
                &.${optionClasses.highlighted}.${optionClasses.selected} {
                    background-color: blue;
                    color: aliceblue;
                }
            
                &.${optionClasses.disabled} {
                    color: darkgrey;
                }
            
                &:hover:not(.${optionClasses.disabled}) {
                    background-color: grey;
                    color: darkgrey;
                }
            }
            `}
        </style>
    )
}