import React, { useMemo } from 'react';
import MonospaceText from '../MonospaceFont/MonospaceFont';
import './markedCellCountdown.css';

// displays the remaining number of cells to be marked (to equal the number of mines)
// this doesn't mean the correct cells are marked
const MarkedCellCountdown = ({ markedCellCount, mineCount }) => {
    const remainingMarkCountStr = useMemo(() => {
        const newRemainingMarkCount = mineCount - markedCellCount;
        if (!newRemainingMarkCount || newRemainingMarkCount < 0) return '0';
        return newRemainingMarkCount.toString();
    }, [markedCellCount, mineCount]);

    return (
        <div className='mcc-container'>
            <img 
                className='mcc-icon'
                src='/images/icon/flag.png' 
                alt='Flag' 
            />
            <p className='mcc-text'>
                <MonospaceText textStr={remainingMarkCountStr} />
            </p>
        </div>
    )
}

export default MarkedCellCountdown;