import React from 'react';
import Select from '../Select/Select';

const DifficultySelector = ({
    difficulty='easy',
    onDifficultyChange=(diff)=>null
}) => {
    return (
        <Select
            className='diff-select'
            tileSize='md'
            value={difficulty}
            onChange={onDifficultyChange}
            options={[
                { value: 'easy', label: 'Easy' },
                { value: 'medium', label: 'Medium' },
                { value: 'hard', label: 'Hard' },
            ]}
        />
    )
}

export default DifficultySelector;