import React, { useMemo } from 'react';
import './monospaceText.css';

// returns each character as a span with a fixed width
// so that the font looks monospaced
const MonospaceText = ({ textStr }) => {
    const textCharList = useMemo(() => {
        return textStr.split('');
    }, [textStr]);

    return (
        <>
            {textCharList.map((char, index) => {
                return (
                    <span key={index} className='monospace-char'>
                        {char}
                    </span>
                )
            })}
        </>
    )
}

export default MonospaceText;
